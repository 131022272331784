import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, platform = 'default', sx, ...other }, ref) => {
  const theme = useTheme();

  // Define the path to your image logo
  const logo = (
    <Box
      ref={ref}
      component="img" // Change to img to load an image
      src="/assets/icons/platforms/ic_posgrado.png" // Dynamic platform logo path
      alt="Logo" // Add an alt text for accessibility
      sx={{
        width: 70, // Adjust the size as needed
        height: 40, // Adjust the size as needed
        display: 'inline-flex',
        cursor: 'pointer',
        ...sx, // Apply any custom styles passed via props
      }}
      {...other}
    />
  );

  if (disabledLink) {
    return logo; // If link is disabled, just return the logo without link
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo} {/* Wrap the logo in a link if the link is not disabled */}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  platform: PropTypes.string, // Add PropType validation for platform
  sx: PropTypes.object,
};

export default Logo;
