// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useContext } from 'react';
import { AuthContext } from 'src/auth/context/jwt';
import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';

// components
import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { userMock } = useMockedUser();

  const { user } = useContext(AuthContext);
  const { t } = useLocales();

  // Ruta del avatar por defecto (puede ser la imagen que subiste)
  const defaultAvatar =
    'https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_25.jpg'; // Cambia esto a la ruta correcta
  return (
    <Stack sx={{ px: 2, py: 5, textAlign: 'center' }}>
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar
            src={defaultAvatar} // Si hay avatar personalizado, se muestra. Si no, usa defaultAvatar.
            alt={user?.nombre_completo?.nombre || 'Usuario'}
            sx={{ width: 48, height: 48 }}
          />
          <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            Bienvenido
          </Label>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.nombre_completo?.nombre} {user?.nombre_completo?.paterno}{' '}
            {user?.nombre_completo?.materno}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.usuario || 'TeamPsg'}
          </Typography>
        </Stack>
        <Button variant="contained" href={paths.teamPsg} target="_blank" rel="noopener">
          TeamPsg
        </Button>
      </Stack>
    </Stack>
  );
}
