import axios from 'axios';
// import { HOST_API } from 'src/config-global';
import { ASSETS_API, ASSETS_API_CORRESPONDENCIA, ASSETS_API_TRAMITES } from 'src/config-global';

// ----------------------------------------------------------------------

// const axiosInstance = axios.create({ baseURL: HOST_API });
const axiosCorrespondenciaInstance = axios.create({ baseURL: ASSETS_API_CORRESPONDENCIA });
const axiosTramitesInstance = axios.create({ baseURL: ASSETS_API_TRAMITES });
const axiosInstance = axios.create({ baseURL: ASSETS_API });

axiosCorrespondenciaInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Algo salió mal')
);

axiosTramitesInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Algo salió mal')
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Algo salió mal')
);

// export default axiosCorrespondenciaInstance;
// export { axiosTramitesInstance, axiosInstance };
export default axiosCorrespondenciaInstance; // Asegúrate de que esto esté aquí
export { axiosTramitesInstance }; // Exportación de axiosTramitesInstance
// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });
  // const res = await axiosCorrespondenciaInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/v1/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },

  // correspondencia wil
  hoja_ruta: {
    buscarAsignacion: '/api/v1/buscar-asignacion-persona?filters[es_director]=true',
    buscarProgramas: '/api/v1/programa?limit=10&page=1&search=',
    buscarPersona: '/api/v1/persona?limit=10&page=1&search=',
    crearHojaRuta: 'api/v1/solicitud-tramite',
    buscarTramites: 'api/v1/tramite?limit=150&search=',
    buscarUnidadesOrigen: '/api/v1/unidades-origen?page=10&filters[es_central]=true&search=',
    ListarRequisitosPresentados: '/api/v1/requisitos-tramite/',
    buscarDestino: '/api/v1/buscar-asignacion-persona?search=',
    instrucciones: '/api/v1/instruccion?limit=10&page=1',
    programas_persona: '/api/v1/programas-persona/',
  },
  asignacion_cargo_area_persona: {
    asignacion:
      '/api/v1/buscar-asignacion-persona?filters[filtrar_usuario]=false&limit=150&search=',
  },
  // DETALLES EN EL MODAL DE LAS DERIVACIONES
  listado_derivaciones: {
    listadogeneral: '/api/v1/solicitud-tramite',
    // listadoGeneral: ''
    detalle: (idSolicitud) => `/api/v1/solicitud-tramite/${idSolicitud}`,
  },

  // DERIVACIONES OFICIAL-COPIA
  derivacion_copia: {
    derivacion: '/api/v1/derivacion',
    derivarSolicitud: '/api/v1/derivar-solicitud',
    derivacion_copia: '/api/v1/derivacion',
    estado: (estado) => `/api/v1/derivacion?limit=100&estado_derivacion=${estado}`,
    contar: '/api/v1/derivacion-contar',
  },
  // http://correspondencialocal.upea.bo/api/v1/solicitud-tramite
  tipo_tramite: {
    tipo: '/api/v1/tipo-documento',
  },
  generar_pdf: {
    pdf: '/api/v1/tramites',
  },

  // tramites daniel
  tramites: {
    tramites: 'tramites',
    clasificacion_tramites: 'clasificacion-tramites',
    // https://stb.posgradoupea.edu.bo/api/v1/requisitos-tramites/ordenado/clasificacion
    requisitos_tramites: 'requisitos-tramites/ordenado/clasificacion',
  },
  requisitos: {
    requisitos: 'requisitos',
    requisitos_tramites: 'requisitos-tramites',
  },

  // CORRESPONDENCIA WILL DOCUMENTOS
  documentos: {
    documento: '/api/v1/documento',
    tipo_documento: '/api/v1/tipo-documento',
    buscar_asignacion_persona: '/api/v1/buscar-asignacion-persona',
    documento_generar: '/api/v1/documento-generar/',
    documento_final_generar: '/api/v1/documento-final-generar/',
    documento_subir: '/api/v1/documento-subir',
  },
  tramitesCorrespondencia: {
    tramites: '/api/v1/tramites',
    // https://correspondencialocal.upea.bo/api/v1/rechazar-tramite
    rechazar_tramite: '/api/v1/rechazar-tramite',
  },

  reportes: {
    reporteSolicitudTramite: '/api/v1/reporte-solicitud-tramite',
    hojaRuta: '/api/v1/hoja-ruta-imprimir',
    hojaRutaFinal: '/api/v1/hoja-ruta-imprimir-detallada',
  },
  dashboard: {
    totalTramites: '/api/v1/principal/totales-tramites',
  },
  conteo: {
    contarEstadosSolicitud: '/api/v1/contar-estados-solicitud',
  },
  // Enpoints de posgraduante acceso para su interfas y que visualize sus programas
  posgraduante: {
    solicitudTramite: '/api/v1/solicitud-tramite/usuario',
  },

  // solicitud tramite
  solicitud_tramite: {
    solicitudTramiteCodigo: '/api/v1/seguimiento/tramite', // https://correspondencialocal.upea.bo/api/v1/seguimiento/tramite
    seguimientoTramite: '/api/v1/seguimiento-tramite', // https://correspondencialocal.upea.bo/api/v1/seguimiento-tramite
  },

  // Instituciones Externas
  instituciones_externas: {
    listarInstituciones: '/api/v1/institucion-externa', // https://correspondencialocal.upea.bo/api/v1/institucion-externa
  },

  // Persona
  persona: {
    registrarPersona: '/api/v1/persona', // https://correspondencialocal.upea.bo/api/v1/persona
  },

  // derivaciones
  derivaciones: {
    derivacionesCopia: '/api/v1/derivacion/derivar-copia', // https://correspondencialocal.upea.bo/api/v1/derivacion/derivar-copia
    observar: '/api/v1/derivacion/observar',
    corregirObservacion: '/api/v1/derivacion/corregir-observacion',
    notificacion: '/api/v1/derivacion/notificacion',
  },
  rutas_nodos_instrucciones: {
    // https://correspondencialocal.upea.bo/api/v1/listar-ruta-tramite
    listar_ruta: '/api/v1/listar-ruta-tramite',
    registrar_nuevo: '/api/v1/ruta-tramite',
    proceso_tramite: '/api/v1/proceso-tramite',
    instruccion_area: '/api/v1/instruccion-area-administrativa',
    primera_derivacion: '/api/v1/sugerencia-primera-derivacion',
    // https://correspondencialocal.upea.bo/api/v1/sugerencia-siguiente-derivacion
    siguiente_derivacion: '/api/v1/sugerencia-siguiente-derivacion',
  },

  // area administrativa
  area_administrativa: {
    listarArea: '/api/v1/area-administrativa',
  },

  // api node will
  api_node: {
    listarClasificaciones: 'tramites/clasificacion',
    listarGradosAcademicos: 'tramites/grado-academico',
    listarTramites: 'tramites',
  }
};
