import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
// utils
import { fToNow } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function NotificationItem({ notification }) {
  const {
    nombre_tramite,
    fecha_derivacion,
    referencia_solicitud,
    referencia_derivacion,
    urgente,
    remitente_derivacion,
  } = notification;

  // Convertir el remitente de JSON a un objeto JavaScript
  const remitente = JSON.parse(remitente_derivacion || '{}');
  const remitenteNombre = `${remitente.nombre_prefijo_profesion} ${remitente.nombre} ${remitente.paterno} ${remitente.materno}`;

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      <Stack sx={{ flexGrow: 1 }}>
        {/* Mostrar el nombre del trámite */}
        <Typography variant="caption" component="div">
          {nombre_tramite}
        </Typography>

        {/* Mostrar la referencia de la solicitud */}
        <Typography variant="caption" color="text.secondary" noWrap>
          {referencia_derivacion}
        </Typography>

        {/* Mostrar el remitente */}
        <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
          Remitente: {remitenteNombre}
        </Typography>

        {/* Mostrar si es urgente */}
        {urgente === "t" && (
          <Typography variant="caption" color="error" sx={{ mt: 0.5 }}>
            <Label>Urgente</Label>
          </Typography>
        )}

        {/* Mostrar la fecha de derivación */}
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Iconify icon="eva:clock-outline" width={16} height={16} />
          <Typography variant="caption" color="text.secondary">
            {formatDistanceToNow(new Date(fecha_derivacion), { addSuffix: true, locale: es })}
          </Typography>
        </Stack>


      </Stack>
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};
