import { m } from 'framer-motion';
import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de importar Link

// @mui
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import axiosCorrespondenciaInstance, { endpoints } from 'src/utils/axios';
// _mock
import { _notifications } from 'src/_mock';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
//
import NotificationItem from './notification-item';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();

  const smUp = useResponsive('up', 'sm');

  // const [currentTab, setCurrentTab] = useState('archived');
  const [notifications, setNotifications] = useState([]);
  const [currentTab, setCurrentTab] = useState('ENTRANTE'); // ENTRANTE u OBSERVADO
  const countEntrantes = notifications.filter((n) => n.estado_derivacion === 'ENTRANTE').length;
  const countObservados = notifications.filter((n) => n.estado_derivacion === 'OBSERVADO').length;


  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue); // Cambiar entre 'ENTRANTE' y 'OBSERVADO'
  };


  // const [notifications, setNotifications] = useState(_notifications);

  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const fetchNotifications = useCallback(async (estado) => {
    try {
      // http://correspondencialocal.upea.bo/api/v1/derivacion/notificacion
      const response = await axiosCorrespondenciaInstance.post(endpoints.derivaciones.notificacion, { estado_derivacion: estado });
      if (response.data.status === 'success') {
        setNotifications(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, []);


  useEffect(() => {
    fetchNotifications(currentTab);
  }, [currentTab, fetchNotifications]);

  const TABS = [
    {
      value: 'ENTRANTE',
      label: 'Entrantes',
      count: notifications.filter((n) => n.estado_derivacion === 'ENTRANTE').length,
    },
    {
      value: 'OBSERVADO',
      label: 'Observados',
      count: notifications.filter((n) => n.estado_derivacion === 'OBSERVADO').length,
    },
  ];



  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notificaciónes de tramites
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={
                ((tab.value === 'archived' || tab.value === currentTab) && 'filled') || 'soft'
              }
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications.map((notification) => (
          <NotificationItem key={notification.id_derivacion} notification={notification} />
        ))}
      </List>
    </Scrollbar>
  );


  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={countEntrantes + countObservados} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>

      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
          <IconButton onClick={handleMarkAllAsRead}>
            <Iconify icon="solar:settings-bold-duotone" />
          </IconButton>
        </Stack>

        <Divider />

        {renderList}

        <Box sx={{ p: 1 }}>
          <Button fullWidth size="large" component={Link} to="#">
            Ver más
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
