// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // todos Nuestros tramites de posgrado
  // tramites: '/tramites',
  components: '/components',
  legalizaciones: '/legalizaciones',
  seguimiento: '/seguimiento',
  nuevo: '/nuevo',
  tramites: '/tramites',
  nuevo_tramite: '/nuevo_tramite',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  teamPsg: 'https://posgrado.upea.bo/team-psg',
  posgrado: 'https://posgrado.upea.bo/',
  posgradoOferta: 'https://posgrado.upea.bo/oferta',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',

  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },

  // post = tramites

  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },

  // verificacion de tramites
  verificacion: {
    root: `/verificacion`,
  },

  // fin tramites
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },

  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },

    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },

    tramites_usuarios: {
      root: `${ROOTS.DASHBOARD}/tramites_usuarios`,
    },

    // bandeja
    tray: {
      root: `${ROOTS.DASHBOARD}/tray`,
      entrance: `${ROOTS.DASHBOARD}/tray/entrance`,
      earrings: `${ROOTS.DASHBOARD}/tray/earrings`,
      mis_tramites: `${ROOTS.DASHBOARD}/tray/mis_tramites`,
      sent: `${ROOTS.DASHBOARD}/tray/sent`,
      // seguimiento de tramites o historial
      recibirderivar: `${ROOTS.DASHBOARD}/tray/recibirderivar`,
      recibir: `${ROOTS.DASHBOARD}/tray/recibir`,
      agregar: `${ROOTS.DASHBOARD}/tray/agregar`,
    },

    // agregar area/departamento

    area: {
      root: `${ROOTS.DASHBOARD}/area`,
      new: `${ROOTS.DASHBOARD}/area/new`,
    },

    // documentos internos
    documentos: {
      root: `${ROOTS.DASHBOARD}/documentos`,
      new: `${ROOTS.DASHBOARD}/documentos/new`,
    },

    hoja_de_ruta: {
      root: `${ROOTS.DASHBOARD}/hoja_de_ruta`,
      new: `${ROOTS.DASHBOARD}/hoja_de_ruta/new`,
    },

    hoja_de_ruta_usuario: {
      root: `${ROOTS.DASHBOARD}/hoja_de_ruta_usuario`,
      listado: `${ROOTS.DASHBOARD}/hoja_de_ruta_usuario/listado`,
    },

    tramitesrequisitos: {
      root: `${ROOTS.DASHBOARD}/tramitesrequisitos`,
    },

    procesos: {
      root: `${ROOTS.DASHBOARD}/procesos`,
    },

    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },

    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },

    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
  },
};
